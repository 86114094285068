<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Targets</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="mr-1" @click="goBack" outlined color="toolbarIcon" text>
          <v-icon left dark>mdi-arrow-left</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="targetData"
              :search="search"
              :footer-props="{
                itemsPerPageOptions: [5, 100, 150, { text: 'All', value: -1 }],
                showCurrentPage: true,
              }"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
      
      <script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user"]),
  },

  data: () => ({
    dataItem: {},
    Items: [],
    search: null,
    showItems: [],
    targetData: [],
    SlpName: null,
    itemToDelete: null,
    confirmDelete: false,
    headers: [
      { text: "Name", value: "target_row.TName" },
      { text: "PeriodStart", value: "target_row.PeriodStart" },
      { text: "PeriodEnd", value: "target_row.PeriodEnd" },
      { text: "Sales Employee", value: "employees.SlpName" },
      { text: "Target Value", value: "target_row.Tvalue" },
      //   { text: "Quantity(UOM)", value: "totalQuantitySold" },
      { text: "Amount", value: "totalAmountSold" },
      { text: "Achievement (%)", value: "achievement_amount_percentage" },
    ],
  }),

  watch: {
    "$route.params.id": {
      handler: "getSlpEmp",
      immediate: true,
    },
  },
  methods: {
    getSlpEmp(val) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/target_rows/${val}`)
        .then((res) => {
          //   console.log(res.ResponseData, "emp target");
          self.targetData = res.ResponseData;

          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
          this.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  created() {},
};
</script>